<template>
  <div>
    <p>
      This just in: You LEGO Universe fans are filling the Creation Lab with inspiring news stories! Enjoy the LEGO Universe team’s fun feedback on just a few of our favorite submissions to the “Create a news report” challenge!
    </p>
    <p>
      Check out <em><router-link :to="{ name: 'story', params: {id: '118067'} }">The newest character reports for duty</router-link></em> to see concept art of the LEGO Universe character who inspired this challenge, and stay tuned to watch him deliver news scoops in a special video series!<br>
      And now, on with the review!
    </p>
    <!-- pic6EB6FA70174E7534A721AE7D185BA222.jpg -->
    <img alt="Newsflash: Steampunk Burst!" src="" class="img-large rounded">
    <p>
      <router-link :to="{ name: 'creation', params: {id: '5a1eb302-2171-4bf7-b3f5-2dc907b8451b', challengeId: 'FC711DAA-2552-4224-BA9E-41D01DFDF591'} }"><strong>Newsflash: Steampunk Burst!</strong></router-link>
      <strong> By: icon109</strong>
    </p>
    <p>
      This creation is a LEGO minifigure-scale reflection of reality. Just like this newsflash, the LEGO Universe News Network features interviews with inventive builders, and it’s easy to imagine a real life reporter saying Phil Brick’s silly puns. What’s more, some members of the LEGO Universe development team dig the same steampunk style as these minifigures!
    </p>

    <!-- picCBB870F26D0E6B24C9627B1766DC6585.jpg -->
    <img alt="Alien News Network" src="" class="img-large rounded">
    <p>
      <router-link :to="{ name: 'creation', params: {id: '53388b59-784e-44f7-b01b-86644a4109d3', challengeId: '53388b59-784e-44f7-b01b-86644a4109d3'} }"><strong>Alien News Network</strong></router-link>
      <strong> By tobywilson</strong>
    </p>
    <p>
      The tables get turned in this imaginative production. Instead of humans reporting on aliens, this newscast comes from the Alien News Network! The report features realistic banter between two alien reporters and a funny interview with a Mars Mission astronaut who certainly isn’t smart enough for rocket science!<br>
    </p>
    <!-- pic2559E84C582C20CB43F54F0575B17BCA.jpg -->
    <img alt="Power Miners Report" src="" class="img-large rounded" >
    <p>
      <router-link :to="{ name: 'creation', params: {id: '6e4f6f19-45ab-4f06-bf1e-b0d5f7b7848d', challengeId: '6e4f6f19-45ab-4f06-bf1e-b0d5f7b7848d'} }"><strong>Power Miners Report: Pt. 1</strong></router-link>
      <strong> By Maze-Redbrick</strong>
    </p>
    <p>
      This news story draws you in with tons of enticing tidbits! Could that grumbling news-hater be part of a sinister plan? Can the Power Miners uncover the cause of the quakes beneath LEGOLAND? Is cameraman Lenny in love with rookie-reporter Brickney? We’ll all have to keep watching for the details!
    </p>
    <p>
      And stay tuned right here to see video of the game’s newest character reporting live from LEGO Universe! In the meantime, keep those incredible creations flowing into the Creation Lab! You’re helping Professor Brickkeeper fill LEGO Universe with inspiration!
    </p>
  </div>
</template>
